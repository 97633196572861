import Layout from '../layout';
import React, { Component } from 'react';
import styled from 'styled-components';
import styling from '../../styling';
import { Page } from '../layout/header';
import { MDXRenderer } from 'gatsby-plugin-mdx';

//#region Styled Components
const StyledArticle = styled.article`
  margin: 0 auto;
  width: 35rem;
  padding-bottom: 5rem;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.617924em;
    line-height: 1.5;

    + div > p:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 1.618em;
  }

  h3 {
    font-size: 1.4em;
  }

  h4 {
    font-size: 1.25em;
  }

  h5 {
    font-size: 1.1em;
  }

  h6 {
    font-size: 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
    margin-bottom: 0.5em;
    line-height: 1.5;

    + p {
      margin-top: 0;
    }
  }

  pre {
    overflow: auto;
  }

  blockquote {
    margin: 2em -2em;
    padding: 2em;
    font-style: italic;
    background-color: ${styling.colors.gray.L95};
    border-radius: ${styling.sizes.borderRadius};

    p {
      margin: 0;
    }

    @media (max-width: ${styling.sizes.contentWidthNumber * 0.6}em) {
      margin-left: -${styling.sizes.contentWidthPaddingNumber * 0.2}em;
      margin-right: -${styling.sizes.contentWidthPaddingNumber * 0.2}em;
      padding-left: ${styling.sizes.contentWidthPaddingNumber * 0.2}em;
      padding-right: ${styling.sizes.contentWidthPaddingNumber * 0.2}em;
    }

    @media (max-width: ${styling.sizes.contentWidthNumber * 0.5}em) {
      margin-left: -1em;
      margin-right: -1em;
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .cp_embed_wrapper {
    margin-bottom: 1em;
  }

  ul {
    margin-top: 0.25em;
    margin-bottom: 0;
  }

  li {
    > p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
  }
`;
//#endregion

interface Props {
  location: Location;
  resources: Resources;
}

class ResourcesComponent extends Component<Props> {
  public render() {
    const resources = this.props.resources;
    return (
      <Layout
        currentPage={Page.Resources}
        title={resources.frontmatter.title}
        description={`Resources about ${resources.frontmatter.title}`}
        location={this.props.location}>
        <main>
          <StyledArticle>
            <h1>Resources about {resources.frontmatter.title}</h1>
            <MDXRenderer>{resources.body}</MDXRenderer>
          </StyledArticle>
        </main>
      </Layout>
    );
  }
}

interface PageProps {
  location: Location;
  pageContext: {
    resources: Resources;
  };
}

const Wrapper: React.SFC<PageProps> = ({ location, pageContext: { resources } }) => (
  <ResourcesComponent location={location} resources={resources} />
);

export default Wrapper;
